<template>
  <v-card>
    <v-card-title class="justify-center">
      Información de la reserva <br />
      <v-chip color="primary" class="mt-2" v-if="selectedItem.code">
        {{ selectedItem.code }}
      </v-chip>

      <v-btn
        style="position: absolute; top: 10px; right: 10px"
        @click="$emit('cancel')"
        icon
        color="primary"
        class="ml-7"
        ><v-icon>fas fa-times</v-icon></v-btn
      >
    </v-card-title>

    <v-card-text class="mt-5">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <p>
              Usuario <br />
              {{ selectedItem.userName }}
            </p>
            <v-divider class="mb-3"></v-divider>
          </v-col>

          <v-col v-if="selectedItem.userPhone" cols="12" sm="6" md="6">
            <p>
              Teléfono <br />
              {{ selectedItem.userPhone }}
            </p>

            <v-divider class="mb-3"></v-divider>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="6">
            <p>
              Espacio reservado <br />
              {{ selectedItem.spaceName }}
            </p>
            <v-divider class="mb-3"></v-divider>
          </v-col> -->

          <!-- <v-col cols="12" sm="6" md="6">
            <p>
              Fecha reservada <br />
              {{ selectedItem.date }}
            </p>

            <v-divider class="mb-3"></v-divider>
          </v-col> -->

          <!-- <v-col cols="12" sm="6" md="6">
            <p>
              Horario reservado <br />
              {{ selectedItem.startHour }} - {{ selectedItem.endHour }}
            </p>
            <v-divider class="mb-3"></v-divider>
          </v-col> -->

          <v-col cols="12" sm="6" md="6">
            <p>
              Correo electrónico <br />
              {{ selectedItem.userEmail ?? "" }}
            </p>
            <!-- <v-divider class="mb-3"></v-divider> -->
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <p>
              Fecha de creación <br />
              {{ selectedItem.createdAt | filterDate }}
            </p>
            <!-- <v-divider class="mb-3"></v-divider> -->
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <p style="color: #ff8a00">Espacios reservados</p>
          </v-col>

          <v-col cols="12" sm="4" md="4"> Espacio </v-col>
          <v-col cols="12" sm="4" md="4"> Fecha </v-col>
          <v-col cols="12" sm="4" md="4"> Hora </v-col>

          <v-col class="mb-0 pb-0 mt-0 pt-0" cols="12" sm="12" md="12">
            <v-divider class="mb-3"></v-divider
          ></v-col>

          <v-row v-for="(e, id) of selectedItem.spaces" :key="id">
            <v-col cols="12" sm="4" md="4"> {{e.spaceName}} </v-col>
            <v-col cols="12" sm="4" md="4"> {{e.date}} </v-col>
            <v-col cols="12" sm="4" md="4"> {{e.startHour}} - {{e.endHour}} </v-col>
          </v-row>

          <!-- {{ selectedItem.spaces }} -->
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-row>
        <v-col
          v-if="selectedItem.status == 'available'"
          cols="12"
          sm="6"
          md="6"
        >
          <v-btn
            :loading="loading"
            @click="bookingUsed"
            block
            outlined
            color="primary"
            >Usar reserva</v-btn
          >
        </v-col>

        <v-col
          v-if="selectedItem.status == 'available'"
          cols="12"
          sm="6"
          md="6"
        >
          <v-btn
            :loading="loading"
            @click="rescheduleDialog = true"
            block
            outlined
            color="primary"
            >Modificar</v-btn
          >
        </v-col>

        <!-- <v-col cols="12" sm="12" md="12">
          <v-btn
            :disabled="loading"
            @click="$emit('cancel')"
            block
            outlined
            color="red"
            >Salir</v-btn
          >
        </v-col> -->
      </v-row>
    </v-card-actions>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      v-if="rescheduleDialog"
      v-model="rescheduleDialog"
      fullscreen
      transition="dialog-top-transition"
      max-width="900"
    >
      <reschedule
        :spaces="spaces"
        :schedule="schedule"
        @success="$emit('success')"
        :bookings="bookings"
        @cancel="rescheduleDialog = false"
        :selectedItem="selectedItem"
      ></reschedule>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { db } from "@/firebase";
import { mapState } from "vuex";
import reschedule from "./reschedule";

export default {
  props: ["selectedItem", "spaces", "schedule", "bookings"],

  components: {
    reschedule,
  },

  data() {
    return {
      loading: false,
      rescheduleDialog: false,
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    async bookingUsed() {
      this.loading = true;

      await db
        .collection("reservations")
        .doc(this.selectedItem[".key"])
        .update({
          status: "used",
          bookingUsedAt: new Date(),
          bookingStatusChangedBy: this.user[".key"],
        });

      this.loading = false;
      this.$emit("used");
    },

    // reschedule() {
    //   this.rescheduleDialog = true
    // },
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) {
        return "no disponible";
      }

      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY hh:mm A");
    },
  },
};
</script>

<style scoped>
</style>