<template>
  <div>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="items"
      sort-by="name"
      v-if="roleAllowed('read')"
      :loading="loading"
      :items-per-page="10"
      style="border-radius: 20px"
    >
      <template v-slot:top>
        <v-toolbar flat style="border-radius: 20px 20px 0px 0px">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            v-if="showDateControl"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Seleccionar mes"
                readonly
                style="max-width: 200px"
                v-bind="attrs"
                v-on="on"
                :loading="loading"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              v-model="date"
              color="primary"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false"> Cancelar </v-btn>
              <v-btn
                color="primary"
                @click="
                  $refs.menu.save(date);
                  $emit('dateChanged', date);
                "
              >
                Filtrar
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            v-if="roleAllowed('download') && showDownloadControl"
            class="mr-3"
            outlined
            color="primary"
          >
            <v-icon class="mr-3">far fa-file-excel</v-icon> Descargar</v-btn
          >

          <v-btn
            :loading="loading"
            outlined
            class="mr-3"
            v-if="roleAllowed('update') && showOrganize"
            @click="$emit('organize')"
            color="primary"
          >
            <v-icon class="mr-3">fas fa-sitemap</v-icon> Organizar</v-btn
          >

          <v-btn
            :loading="loading"
            outlined
            v-if="roleAllowed('create')"
            @click="$emit('add')"
            color="primary"
          >
            <v-icon class="mr-3">fas fa-plus</v-icon> Nuevo</v-btn
          >
        </v-toolbar>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-switch
          :disabled="loading || !roleAllowed('update')"
          v-model="item.active"
          @change="$emit('update', item)"
        ></v-switch>
      </template>

      <template v-slot:[`item.inMaintenance`]="{ item }">
        <v-switch
          :disabled="loading || !roleAllowed('update')"
          v-model="item.inMaintenance"
          @change="$emit('update', item)"
        ></v-switch>
      </template>

      <template v-slot:[`item.discountActive`]="{ item }">
        <v-switch
          :disabled="loading || !roleAllowed('update')"
          v-model="item.discountActive"
          @change="$emit('update', item)"
        ></v-switch>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ item.price | currency }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ item.total | currency }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | toDate }}
      </template>

      <template v-slot:[`item.discountAmount`]="{ item }">
        <p
          style="font-size: 14px; color: green"
          v-if="item.discountType == 'fixed' && item.discountAmount"
        >
          {{ item.discountAmount | currency }}
        </p>
        <p v-if="item.discountType == 'percentage' && item.discountAmount">
          {{ item.discountAmount }}%
          <small style="font-size: 11px; color: green"
            >({{
              ((item.discountAmount * item.price) / 100) | currency
            }})</small
          >
        </p>
      </template>

      <template v-slot:[`item.image`]="{ item }">
        <v-avatar
          style="cursor: pointer"
          @click="showImage(item.image)"
          v-if="item.image && item.image.original"
        >
          <v-img :src="item.image.original"></v-img>
        </v-avatar>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <v-chip v-if="item.email" @click="copy(item.email)" color="secondary">
          {{ item.email }}</v-chip
        >
      </template>

      <template v-slot:[`item.userEmail`]="{ item }">
        <v-chip v-if="item.userEmail" @click="copy(item.userEmail)" color="secondary">
          {{ item.userEmail }}</v-chip
        >
      </template>

      <template v-slot:[`item.code`]="{ item }">
        <v-chip v-if="item.code" @click="copy(item.code)" color="primary">
          {{ item.code }}</v-chip
        >
      </template>

      <template v-slot:[`item.userPhone`]="{ item }">
        <v-chip v-if="item.userPhone" @click="copy(item.userPhone)" color="secondary">
          {{ item.userPhone }}</v-chip
        >
      </template>

      <template v-slot:[`item.phone`]="{ item }">
        <v-chip v-if="item.phone" @click="copy(item.phone)" color="secondary">
          {{ item.phone }}</v-chip
        >
      </template>

      <template v-slot:[`item.showImage`]="{ item }">
        <v-switch
          :disabled="loading || !roleAllowed('update')"
          v-model="item.showImage"
          @change="$emit('update', item)"
        ></v-switch>
      </template>

      <template v-slot:[`item.info`]="{ item }">
        <v-btn
          :disabled="loading"
          outlined
          @click="$emit('information', item)"
          color="#aea659"
        >
          Información</v-btn
        >
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :disabled="loading"
          v-if="showReservations"
          outlined
          @click="$emit('reservations', item)"
          color="#aea659"
        >
          Reservas</v-btn
        >

        <v-btn
          :disabled="loading"
          v-if="roleAllowed('update')"
          outlined
          @click="$emit('edit', item)"
          color="primary"
          class="ml-3"
        >
          Editar</v-btn
        >
        <v-btn
          :disabled="loading"
          v-if="roleAllowed('delete') && !hideDelete"
          outlined
          color="red"
          @click="$emit('delete', item)"
          class="ml-3"
        >
          Eliminar</v-btn
        >
      </template>
    </v-data-table>
    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      transition="dialog-bottom-transition"
      v-if="imageDialog"
      v-model="imageDialog"
      max-width="500"
    >
      <v-img
        :lazy-src="selectedImage.loading"
        :src="selectedImage.original"
      ></v-img>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "table-custom",
  props: [
    "headers",
    "items",
    "loading",
    "showDateControl",
    "showDownloadControl",
    "showOrganize",
    "hideDelete",
    "showReservations",
  ],

  data() {
    return {
      date: moment().format("YYYY-MM"),
      menu: false,
      imageDialog: false,
      selectedImage: null,
    };
  },

  filters: {
    currency(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    toDate(timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, HH:mm A");
    },
  },

  computed: {
    ...mapState(["search", "role"]),
  },

  methods: {
    ...mapActions(["setSnackbar"]),

    showImage(item) {
      this.selectedImage = item;
      this.imageDialog = true;
    },

    roleAllowed(action) {
      return (
        this.role.screens[this.$route.name] &&
        this.role.screens[this.$route.name][action]
      );
    },

    copy(e) {
      navigator.clipboard.writeText(e);

      this.setSnackbar({
        message: `${e} copiado.`,
        color: "green",
      });
    },
  },
};
</script>

<style scoped>
</style>