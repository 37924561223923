<template>
  <v-container fluid>
    <table-custom
      :loading="loading"
      :headers="headers"
      :items="all"
      :showDateControl="true"
      :showInfo="true"
      @information="showInformation"
      @dateChanged="handleDateChanged"
    ></table-custom>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      v-if="detailsDialog"
      v-model="detailsDialog"
      max-width="500"
    >
      <reservation-details
        :loading="loading"
        :selectedItem="selectedItem"
        @cancel="detailsDialog = false"
      ></reservation-details>
    </v-dialog>
  </v-container>
</template>
      
      <script>
import { mapActions, mapState } from "vuex";
import tableCustom from "@/components/table";
import { db } from "@/firebase";
import rules from "@/mixins/rules";
import sort from "@/mixins/sort";
import moment from "moment";
import reservationDetails from "@/components/reservation-details"

export default {
  name: "List",

  components: {
    tableCustom,
    reservationDetails
  },
  mixins: [rules, sort],

  data() {
    return {
      selectedItem: null,
      detailsDialog: false,
      all: [],
      date: moment().format("YYYY-MM"),

      headers: [
        {
          text: "Código",
          value: "code",
        },
        {
          text: "Cliente",
          value: "userName",
        },
        {
          text: "Email",
          value: "userEmail",
        },
        {
          text: "Teléfono",
          value: "userPhone",
        },

        {
          text: "Espacios comprados",
          value: "amountOfSpaces",
        },

        {
          text: "Total",
          value: "total",
        },

        {
          text: "Fecha de compra",
          value: "createdAt",
          width: "250px",
        },

        {
          text: "",
          value: "info",
          align: "end",
        },
      ],
      loading: true,
    };
  },

  watch: {
    selectedAgency(e) {
      if (e) {
        this.getData();
      } else {
        this.all = [];
      }
    },
  },

  computed: {
    ...mapState(["search", "selectedAgency", "user"]),
  },

  methods: {
    ...mapActions(["setPage", "setSnackbar"]),

    showInformation(e) {
      this.detailsDialog  = true
      this.selectedItem = e;
    },

    handleDateChanged(e) {
      this.date = e;
      this.getData();
    },

    async getData() {
      try {
        this.loading = true;

        let from = moment(this.date, "YYYY-MM")
          .startOf("month")
          .toDate();

        let to = moment(this.date, "YYYY-MM")
          .endOf("month")
          .toDate();


        await this.$binding(
          "all",
          db
            .collection("purchases")
            .where("agencyId", "==", this.selectedAgency)
            .where("createdAt", ">=", from)
            .where("createdAt", "<=", to)
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.setSnackbar({
          message: `${error.message} - Reload page.`,
          color: "red",
        });
      }
    },
  },

  mounted() {
    this.setPage({
      title: "Lista de reservas",
      showSearch: true,
      showNavigation: true,
      showAppBar: true,
    });

    this.getData();
  },
};
</script>