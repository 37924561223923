<template>
  <v-container fluid>
    <table-custom
      :loading="loading"
      :headers="headers"
      :items="prices"
      @edit="handleEdit"
      @update="updateItem"
      @delete="handleDelete"
      @add="
        addDialog = true;
        selectedItem = null;
      "
    ></table-custom>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      v-if="addDialog"
      v-model="addDialog"
      transition="dialog-top-transition"
      max-width="500"
    >
      <add
        :fields="fields"
        @update="updateItem"
        :loading="loading"
        @create="createItem"
        @cancel="addDialog = false"
        :prices="prices"
        :selectedItem="selectedItem"
      ></add>
    </v-dialog>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      v-if="deleteDialog"
      v-model="deleteDialog"
      max-width="500"
    >
      <delete
        :loading="loading"
        :title="`${selectedItem.name}`"
        description="¿Está seguro que desea eliminar este registro? una vez eliminado no podrá recuperarlo."
        @confirmed="deleteItem"
        @cancel="deleteDialog = false"
      ></delete>
    </v-dialog>
  </v-container>
</template>
      
      <script>
import { mapActions, mapState } from "vuex";
import tableCustom from "@/components/table";
import { db, fb } from "@/firebase";
import add from "@/components/add";
import rules from "@/mixins/rules";
import sort from "@/mixins/sort";
import Delete from "@/components/delete";

export default {
  name: "Spaces",

  components: {
    tableCustom,
    add,
    Delete,
  },
  mixins: [rules, sort],

  data() {
    return {
      addDialog: false,
      selectedItem: null,
      deleteDialog: false,
      prices: [],
      fields: [
        {
          value: "name",
          type: "text",
          rules: ["required"],
          label: "Ingrese el nombre",
        },
        {
          value: "description",
          type: "textarea",
          label: "Ingrese la descripción",
        },

        {
          value: "price",
          type: "number",
          label: "Ingrese el precio",
        },

         {
          value: "discountAmount",
          type: "number",
          label: "Descuento",
        },

        {
          value: "discountType",
          type: "select",
          label: "Tipo de descuento",
          options: "discountTypes",
        },

        
      ],
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Precio",
          value: "price",
        },
        {
          text: "Descripción",
          value: "description",
        },

         {
          text: "Descuento",
          value: "discountAmount",
        },

        {
          text: "Activo",
          value: "active",
        },

         {
          text: "Activar descuento",
          value: "discountActive",
        },
        {
          text: "",
          value: "actions",
          align: "end",
        },
      ],
      loading: true,
    };
  },

  watch: {
    selectedAgency(e) {
      if (e) {
        this.getData();
      } else {
        this.prices = [];
      }
    },
  },

  computed: {
    ...mapState(["search", "selectedAgency", "user"]),
  },

  methods: {
    ...mapActions(["setPage", "setSnackbar"]),

    handleSuccess() {},

    deleteItem() {
      this.loading = true;

      db.collection("prices")
        .doc(this.selectedItem[".key"])
        .update({
          deletedAt: new Date(),
          deletedBy: this.user[".key"],
          deleted: true,
        })
        .then(() => {
          this.loading = false;
          this.deleteDialog = false;
          this.setSnackbar({
            message: "Registro eliminado exitosamente.",
            color: "green",
          });
        })
        .catch((e) => {
          this.loading = false;

          this.setSnackbar({
            message: e.message,
            color: "red",
          });
        });
    },

    handleDelete(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },

    handleEdit(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },

    createItem(payload) {
      this.loading = true;
      payload.createdAt = new Date();
      payload.createdBy = this.user[".key"];
      payload.agencyId = this.selectedAgency;
      payload.businessId = this.user.businesses[0];

      db.collection("prices")
        .add(payload)
        .then(() => {
          this.loading = false;
          this.addDialog = false;

          this.setSnackbar({
            message: "Registro agregado exitosamente.",
            color: "green",
          });
        })
        .catch((e) => {
          this.loading = false;

          this.setSnackbar({
            message: e.message,
            color: "red",
          });
        });
    },

    updateItem(payload) {
      this.loading = true;

      db.collection("prices")
        .doc(payload[".key"])
        .update({
          active: payload.active,
          editedAt: new Date(),
          editedBy: this.user[".key"],
          name: payload.name,
          description: payload.description ?? "",
          price: payload.price,
          discountActive : payload.discountActive ?? false,
          discountAmount : payload.discountAmount ?? 0,
          discountType : payload.discountType ?? 0,

        })
        .then(() => {
          this.loading = false;
          this.addDialog = false;

          this.setSnackbar({
            message: "Registro actualizado exitosamente.",
            color: "green",
          });
        })
        .catch((e) => {
          this.loading = false;

          this.setSnackbar({
            message: e.message,
            color: "red",
          });
        });
    },

    async getData() {
      try {
        this.loading = true;

        await this.$binding(
          "prices",
          db
            .collection("prices")
            .where("agencyId", "==", this.selectedAgency)
            .where("deleted", "==", false)
        );

        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.setSnackbar({
          message: `${error.message} - Reload page.`,
          color: "red",
        });
      }
    },
  },

  mounted() {
    this.setPage({
      title: "Precios",
      showSearch: true,
      showNavigation: true,
      showAppBar: true,
    });

    this.getData();
  },
};
</script>