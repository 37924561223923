export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Campo obligatorio",
        min: (v) =>
          (v && v.length >= 8) || "Debe ingresar mínimo 8 caracteres.",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Formato de correo inválido.";
        },
      },
    };
  },
  methods: {},
};
