<template>
  <div style="width: 100%">
    <div fluid class="main-login-blur"></div>
    <v-container fluid class="main-login">
      <Transition name="bounce">
        <v-card
          :loading="loading"
          v-if="show"
          style="border-radius: 15px"
          :elevation="5"
          width="450"
          class="pa-2"
        
        >
          <v-card-title class="justify-center">
            <v-img max-width="350" src="@/assets/logo.svg"></v-img>
          </v-card-title>
          <v-card-subtitle class="mt-5 mb-5"
            >Ingresa las credenciales correctas para iniciar
            sesión</v-card-subtitle
          >
          <v-card-text>
            <v-form ref="login-form">
              <v-text-field
                label="Ingresar correo electrónico"
                rounded
               
                persistent-hint
                :rules="[rules.required, rules.email]"
                placeholder="user@server.com"
                filled
                v-model="email"
              ></v-text-field>
              <v-text-field
                label="Ingresar contraseña"
                rounded
                :rules="[rules.required, rules.min]"
                v-model="password"
               
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                filled
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :disabled="loading"
              @click="login"
              height="55"
              color="primary"
              block
           
              >Iniciar sesión</v-btn
            >
          </v-card-actions>
        </v-card>
      </Transition>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { fb } from "@/firebase";
import rules from "@/mixins/rules";
import errors from "@/assets/firebase-errors.json";

export default {
  name: "Login",
  mixins: [rules],
  data() {
    return {
      show: false,
      loading: false,
      email: "",
      password: "",
      showPassword: false,
    };
  },

  methods: {
    ...mapActions(["setPage", "setSnackbar"]),
    login() {
      let validation = this.$refs["login-form"].validate();

      if (!validation) {
        this.setSnackbar({
          message: "Debe llenar todos los campos solicitados.",
          color: "red",
        });

        return;
      }

      this.loading = true;

      fb.auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .catch((error) => {
          this.loading = false;

          this.setSnackbar({
            message:
              errors[error.code] ??
              "Ocurrió un error desconocido, inténtelo nuevamente.",
            color: "red",
          });
        });
    },
  },

  mounted() {
    this.setPage({
      title: null,
      showSearch: false,
      showNavigation: false,
      showAppBar: false,
    });

    setTimeout(() => {
      this.show = true;
    }, 500);
  },
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.main-login-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/inercia-covers/o/3.png?alt=media&token=46dc7bbb-6638-4028-9f65-d1d58426a8c7");
  height: 100vh;
  background-position: right;
  background-size: cover;
  filter: blur(4px);
}

.main-login {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  align-items: center;
  left: 0;
  width: 100%;
  height: 100vh;
}
</style>