import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "incercia-setting",
  storage: window.localStorage,
  modules: ["selectedAgency"],
});

export default new Vuex.Store({
  state: {
    page: {
      description: "",
      title: "",
      showSearch: false,
      showNavigation: false,
      showAppBar: false,
      showMenuIcon : false
    },
    search: "",
    selectedAgency: "",
    user: null,
    snackbarText: "",
    snackbar: false,
    snackbarColor: "red",
    role: {},
  },
  getters: {},
  mutations: {
    updatePage(state, target) {
      state.page = target;
    },
    updateSearch(state, target) {
      state.search = target;
    },
    updateAgency(state, target) {
      state.selectedAgency = target;
    },
    updateUser(state, target) {
      state.user = target;
    },
    updateSnackbar(state, target) {
      state.snackbarText = target.message;
      state.snackbarColor = target.color;
      state.snackbar = true;
    },
    _closeSnackbar(state, target) {
      state.snackbar = false;
    },

    updateRole(state, target) {
      state.role = target;
    },
  },
  actions: {
    setPage: ({ commit, state }, payload) => {
      commit("updatePage", payload);
    },
    setSearch: ({ commit, state }, payload) => {
      commit("updateSearch", payload);
    },

    setAgency: ({ commit, state }, payload) => {
      commit("updateAgency", payload);
    },

    setUser: ({ commit, state }, payload) => {
      commit("updateUser", payload);
    },
    setSnackbar: ({ commit, state }, payload) => {
      commit("updateSnackbar", payload);
    },
    closeSnackbar: ({ commit, state }, payload) => {
      commit("_closeSnackbar", false);
    },

    setRole: ({ commit, state }, payload) => {
      commit("updateRole", payload);
    },
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
