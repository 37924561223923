<template>
  <v-container fluid>
    <table-custom
      :loading="loading"
      :headers="headers"
      :items="users"
      @edit="handleEdit"
      @update="updateItem"
      @reservations="handleReservations"
      :showReservations="true"
      :hideDelete="true"
      @add="
        addDialog = true;
        selectedItem = null;
      "
    ></table-custom>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      v-if="addDialog"
      v-model="addDialog"
      transition="dialog-top-transition"
      max-width="500"
    >
      <add
        :fields="fields"
        @update="updateItem"
        :loading="loading"
        @create="createItem"
        @cancel="addDialog = false"
        :selectedItem="selectedItem"
      ></add>
    </v-dialog>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      transition="dialog-bottom-transition"
      v-if="reservationsDialog"
      v-model="reservationsDialog"
      persistent
      max-width="90%"
    >
      <user-reservations @cancel="reservationsDialog = false" :user="selectedItem" />
    </v-dialog>
  </v-container>
</template>
  
  <script>
import { mapActions, mapState } from "vuex";
import tableCustom from "@/components/table";
import { db, fb } from "@/firebase";
import add from "@/components/add";
import rules from "@/mixins/rules";
import sort from "@/mixins/sort";
import { nanoid } from "nanoid";
import userReservations from "@/components/user-reservations";

export default {
  name: "Users",

  components: {
    tableCustom,
    add,
    userReservations,
  },
  mixins: [rules, sort],

  data() {
    return {
      addDialog: false,
      selectedItem: null,
      reservationsDialog: false,
      fields: [
        {
          value: "name",
          type: "text",
          rules: ["required"],
          label: "Ingrese el nombre completo",
        },
        {
          value: "email",
          type: "text",
          rules: ["required", "email"],
          label: "Ingrese el correo electrónico",
        },

        {
          value: "phone",
          type: "phone",
          rules: ["required"],
          label: "Ingrese el número de teléfono",
        },
      ],
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Correo",
          value: "email",
        },

        {
          text: "Teléfono",
          value: "phone",
        },

        // {
        //   text: "Rol",
        //   value: "role",
        // },

        {
          text: "Activo",
          value: "active",
        },

        {
          text: "",
          value: "actions",
          align: "end",
          width: "300px",
        },
      ],
      users: [],
      loading: true,
    };
  },

  watch: {
    selectedAgency(e) {
      if (e) {
        this.getData();
      } else {
        this.users = [];
      }
    },
  },

  computed: {
    ...mapState(["search", "selectedAgency", "user"]),

    getItem() {
      //   return this.sortedArray(this.spaces);
    },
  },

  methods: {
    ...mapActions(["setPage", "setSnackbar"]),

    handleSuccess() {},

    handleReservations(e) {
      this.reservationsDialog = true;
      this.selectedItem = e;
    },

    deleteItem() {
      this.loading = true;

      db.collection("users")
        .doc(this.selectedItem[".key"])
        .update({
          deletedAt: new Date(),
          deletedBy: this.user[".key"],
          deleted: true,
        })
        .then(() => {
          this.loading = false;
          this.deleteDialog = false;
          this.setSnackbar({
            message: "Registro eliminado exitosamente.",
            color: "green",
          });
        })
        .catch((e) => {
          this.loading = false;

          this.setSnackbar({
            message: e.message,
            color: "red",
          });
        });
    },

    handleEdit(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },

    createItem(e) {
      this.loading = true;

      let data = {
        email: e.email,
        password: nanoid(),
        name: e.name,
        phone: `+${e.callingCode}${e.phone}`,
      };

      this.loading = true;

      var createUser = fb.functions().httpsCallable("create-create");
      createUser(data)
        .then((response) => {
          this.setSnackbar({
            message: response.data.message,
            color: "green",
          });

          this.loading = false;
          this.addDialog = false;
        })
        .catch((err) => {
          this.loading = false;

          this.setSnackbar({
            message: err.message,
            color: "red",
          });
        });
    },

    updateItem(e) {
      this.loading = true;

      let data = {
        email: e.email,
        name: e.name,
        active: e.active,
        userId: e[".key"],
      };

      if (e.callingCode && e.phone) {
        data.phone = `+${e.callingCode}${e.phone}`;
      }

      this.loading = true;

      var createUser = fb.functions().httpsCallable("update-update");
      createUser(data)
        .then((response) => {
          this.setSnackbar({
            message: response.data.message,
            color: "green",
          });

          this.loading = false;
          this.addDialog = false;
        })
        .catch((err) => {
          this.loading = false;

          this.setSnackbar({
            message: err.message,
            color: "red",
          });
        });

      //   db.collection("users")
      //     .doc(payload[".key"])
      //     .update({
      //       active: payload.active,
      //       editedAt: new Date(),
      //       editedBy: this.user[".key"],
      //       name: payload.name,
      //       email: payload.email,
      //     })
      //     .then(async () => {
      //       this.loading = false;
      //       this.addDialog = false;

      //       this.setSnackbar({
      //         message: "Registro actualizado exitosamente.",
      //         color: "green",
      //       });
      //     })
      //     .catch((e) => {
      //       this.loading = false;

      //       this.setSnackbar({
      //         message: e.message,
      //         color: "red",
      //       });
      //     });
    },

    async getData() {
      try {
        this.loading = true;
        await this.$binding(
          "users",
          db
            .collection("users")
            .where("deleted", "==", false)
            .where("type", "==", "client")
          // .orderBy("name", "asc")
        );

        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.setSnackbar({
          message: `${error.message} - Reload page.`,
          color: "red",
        });
      }
    },
  },

  mounted() {
    this.setPage({
      title: "Usuarios",
      showSearch: true,
      showNavigation: true,
      showAppBar: true,
    });

    this.getData();
  },
};
</script>