<template>
  <v-card>
    <v-card-title class="justify-center">
      {{ selectedItem ? "Editar registro" : "Nuevo registro" }}
    </v-card-title>
    <v-card-text class="mt-3">
      <v-form ref="add-form">
        <div v-for="(field, id) of fields" :key="id">
          <v-text-field
            :label="field.label"
            :disabled="loading"
            :rules="getRules(field)"
            v-if="field.type == 'text'"
            v-model="item[field.value]"
            outlined
          >
          </v-text-field>

          <v-text-field
            :label="field.label"
            :disabled="loading"
            :rules="getRules(field)"
            type="number"
            v-if="field.type == 'number'"
            v-model.number="item[field.value]"
            outlined
          >
          </v-text-field>

          <div
            v-if="field.type == 'phone'"
            style="display: grid; grid-template-columns: 30% 70%"
          >
            <v-select
              item-text="name"
              item-value="callingCode"
              outlined
              v-model="callingCode"
              @change="codeChanged"
              :items="countries"
            >
              <template v-slot:selection="data">
                +{{ data.item.callingCode }}
              </template>

              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <img
                      :src="require(`@/assets/countries/${data.item.flag}`)"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-select>

            <v-text-field
              :label="field.label"
              :disabled="loading"
              :rules="getRules(field)"
              v-model.number="item[field.value]"
              type="number"
              outlined
            >
            </v-text-field>
          </div>

          <v-select
            :label="field.label"
            :disabled="loading"
            :rules="getRules(field)"
            :items="hours"
            v-if="field.type == 'hourPicker'"
            v-model="item[field.value]"
            outlined
          />

          <v-textarea
            rows="2"
            :label="field.label"
            :disabled="loading"
            v-if="field.type == 'textarea'"
            v-model="item[field.value]"
            :rules="getRules(field)"
            outlined
          >
          </v-textarea>

          <v-select
            outlined
            :label="field.label"
            item-text="name"
            v-model="item[field.value]"
            item-value=".key"
            :rules="getRules(field)"
            v-if="field.type == 'select'"
            :items="getOptions(field)"
          ></v-select>

          <v-file-input
            v-if="field.type == 'image'"
            :label="field.label"
            outlined
            show-size
            v-model="item[field.value]"
            prepend-icon="fas fa-camera"
            accept="image/*"
          ></v-file-input>

          <div v-if="field.type == 'fees'">
            <p style="color: #ff8a00">Tarifas adicionales</p>

            <div
              v-for="(e, id) of fees"
              :key="id"
              class="pa-5 mb-10"
              style="
                border: 0.1px solid #3c3c3c;
                width: 100%;
                position: relative;
              "
            >
              <v-icon
                @click="deleteFee(id)"
                x-small
                style="
                  position: absolute;
                  right: 0px;
                  bottom: 0px;
                  padding: 10px;
                  cursor: pointer;
                  color: #ffffff;
                  background-color: #3c3c3c;
                "
              >
                fas fa-trash</v-icon
              >
              <v-row>
                <v-col class="pb-0 mb-0" cols="6" sm="4" md="4">
                  <v-text-field
                    hide-details
                    label="Tarifa"
                    outlined
                    v-model="e.name"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="pb-0 mb-0" cols="6" sm="4" md="4">
                  <v-text-field
                    hide-details
                    type="number"
                    label="Valor"
                    outlined
                    v-model.number="e.amount"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="pb-0 mb-0" cols="6" sm="4" md="4">
                  <v-select
                    hide-details
                    outlined
                    label="Tipo de tarifa"
                    item-text="name"
                    v-model="e.type"
                    item-value=".key"
                    :items="discountTypes"
                  ></v-select>
                </v-col>

                <v-col class="pt-0 mt-0" cols="6" sm="4" md="4">
                  <v-switch
                    hide-details
                    v-model="e.active"
                    label="Activo"
                  ></v-switch>
                </v-col>
                <v-col class="pt-0 mt-0" cols="6" sm="4" md="4">
                  <v-switch
                    hide-details
                    v-model="e.feeBySpace"
                    label="Cargo por espacio"
                  ></v-switch>
                </v-col>
              </v-row>
            </div>

            <div style="display: flex; justify-content: flex-end">
              <v-btn @click="addFee"
                ><v-icon class="mr-5">fas fa-plus</v-icon> Agregar tarifa</v-btn
              >
            </div>
          </div>
        </div>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-btn
            :disabled="loading"
            @click="$emit('cancel')"
            block
            outlined
            color="red"
            >Cancelar</v-btn
          >
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-btn :loading="loading" @click="save" block color="primary"
            >Guardar</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from "@/mixins/rules";
import { mapActions } from "vuex";
import moment from "moment-timezone";
import countries from "@/assets/countries/countries.json";

export default {
  props: ["fields", "selectedItem", "loading", "prices", "hasFees"],
  mixins: [rules],

  data() {
    return {
      item: {
        active: true,
        deleted: false,
      },
      callingCode: "504",
      hours: [],
      fees: [],
      countries: countries,
      sizes: [
        { name: "Pequeño", ".key": "small" },
        { name: "Mediano", ".key": "medium" },
        { name: "Grande", ".key": "big" },
      ],

      discountTypes: [
        { name: "Fijo", ".key": "fixed" },
        { name: "Porcentual", ".key": "percentage" },
      ],
    };
  },

  methods: {
    ...mapActions(["setSnackbar"]),

    getRules(field) {
      let rules = [];

      if (field.rules && field.rules.length) {
        for (let i = 0; i < field.rules.length; i++) {
          rules.push(this.rules[field.rules[i]]);
        }
      }
      return rules;
    },
    addFee() {
      this.fees.push({
        active: true,
        amount: 10,
        feeBySpace: true,
        name: "",
        type: "fixed",
      });

      this.item.fees = this.fees;
    },

    deleteFee(index) {
      this.item.fees = this.item.fees.filter((e, id) => id != index);
      this.item.fees = this.fees;
    },
    save() {
      let validation = this.$refs["add-form"].validate();

      if (validation) {
        this.$emit(this.selectedItem ? "update" : "create", this.item);
      } else {
        this.setSnackbar({
          message: "Ingrese todos los valores requeridos.",
          color: "red",
        });
      }
    },

    codeChanged(e) {
      this.item.callingCode = e;
    },

    getOptions(field) {
      return this[field.options];
    },
  },
  mounted() {
    for (let i = 1; i < 24; i++) {
      this.hours.push(moment(i, "hh").format("HH:mm"));
      this.hours.push(moment(`${i}:30`, "hh:mm").format("HH:mm"));
    }

    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);

      if (this.item.phone) {
        this.callingCode = this.item.phone.substring(1, 4);
        this.item.phone = this.item.phone.substring(4, this.item.phone.length);
      }
    }

    if (this.callingCode) {
      this.item.callingCode = "504";
    }

    if (this.hasFees) {
      this.fees = this.item.fees ?? [];
    }

  },
};
</script>

<style scoped lang="scss">
.wrap-bandera {
  width: 100px;
  height: 20px;
  padding: 5px;
}
.wrap-flag {
  position: relative;
  .flag {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 45px;
      height: 35px;
      border-radius: 8px;
    }
    .select-flag {
      height: 250px;
      width: 100px;
      left: 0;
      top: 0;
      overflow-y: scroll;
      position: absolute;
      background-color: #f2f2f2;
      z-index: 100;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.435) 2px 2px 20px;
    }
  }
}

.avatar-img {
  width: 50px;
}
</style>