<template>
  <v-card>
    <v-card-title class="justify-center"> Eliminar {{ title }} </v-card-title>
    <v-card-text class="mt-3"> 
        {{ description }}
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-btn
            :disabled="loading"
            @click="$emit('cancel')"
            block
         
            color="red"
            >Cancelar</v-btn
          >
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-btn
            :loading="loading"
            @click="$emit('confirmed')"
            block
        
            color="primary"
            >Eliminar</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
  
  <script>
export default {
  props: ["title", "description", "loading"],
};
</script>
