<template>
  <v-card>
    <v-card-title class="justify-center" style="flex-direction: column">
      Información de la reserva <br />
      <div>
        <v-chip color="primary" class="mt-2" v-if="selectedItem.code">
          {{ selectedItem.code }}
        </v-chip>
      </div>

      <v-btn
        style="position: absolute; top: 10px; right: 10px"
        @click="$emit('cancel')"
        icon
        color="primary"
        class="ml-7"
        ><v-icon>fas fa-times</v-icon></v-btn
      >
    </v-card-title>

    <v-card-text class="mt-5">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <p>
              Usuario <br />
              {{ selectedItem.userName }}
            </p>
            <v-divider class="mb-3"></v-divider>
          </v-col>

          <v-col v-if="selectedItem.userPhone" cols="12" sm="6" md="6">
            <p>
              Teléfono <br />
              {{ selectedItem.userPhone }}
            </p>

            <v-divider class="mb-3"></v-divider>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <p>
              Espacio reservado <br />
              {{ selectedItem.spaceName }}
            </p>
            <v-divider class="mb-3"></v-divider>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <p>
              Fecha reservada <br />
              {{ selectedItem.date }}
            </p>

            <v-divider class="mb-3"></v-divider>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <p>
              Método de pago <br />
              {{
                selectedItem.paymentMethodName == "transference"
                  ? "Transferencia"
                  : selectedItem.paymentMethodName == "cash"
                  ? "Efectivo"
                  : "Tarjeta Crédito/Débitos"
              }}
            </p>

            <v-divider class="mb-3"></v-divider>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <p>
              Horario reservado <br />
              {{ selectedItem.startHour }} - {{ selectedItem.endHour }}
            </p>
            <v-divider class="mb-3"></v-divider>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <p>
              Fecha de creación <br />
              {{ selectedItem.createdAt | filterDate }}
            </p>
            <v-divider class="mb-3"></v-divider>
          </v-col>

          <v-col v-if="selectedItem.voucher" cols="12" sm="12" md="12">
            <p>Voucher de la transferencia</p>
            <!-- <v-divider class="mb-3"></v-divider> -->

            <v-divider class="mt-3"></v-divider>
            <v-img
              style="cursor: pointer"
              @click="openImage(selectedItem.voucher)"
              max-height="300"
              :src="selectedItem.voucher"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-row
        v-if="
          selectedItem.locaStatus == 'reserved' &&
          selectedItem.transferenceValid
        "
      >
        <v-col
          v-if="selectedItem.status == 'available'"
          cols="12"
          sm="6"
          md="6"
        >
          <v-btn
            :loading="loading"
            @click="bookingUsed"
            block
            outlined
            color="primary"
            >Usar reserva</v-btn
          >
        </v-col>

        <v-col
          v-if="selectedItem.status == 'available'"
          cols="12"
          sm="6"
          md="6"
        >
          <v-btn
            :loading="loading"
            @click="rescheduleDialog = true"
            block
            outlined
            color="primary"
            >Modificar</v-btn
          >
        </v-col>

        <!-- <v-col cols="12" sm="12" md="12">
          <v-btn
            :disabled="loading"
            @click="$emit('cancel')"
            block
            outlined
            color="red"
            >Salir</v-btn
          >
        </v-col> -->
      </v-row>

      <v-row v-if="selectedItem.locaStatus == 'pendingValidation'">
        <v-col cols="12" sm="12" md="12" @click="validateReservation(true)">
          <v-btn width="90%" :loading="loading" outlined color="primary"
            >La transferencia es válida</v-btn
          >
        </v-col>

        <v-col cols="12" sm="12" md="12" @click="validateReservation(false)">
          <v-btn :loading="loading" width="90%" outlined color="red"
            >La transferencia es inválida</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      v-if="rescheduleDialog"
      v-model="rescheduleDialog"
      fullscreen
      transition="dialog-top-transition"
      max-width="900"
    >
      <reschedule
        :spaces="spaces"
        :schedule="schedule"
        @success="$emit('success')"
        :bookings="bookings"
        @cancel="rescheduleDialog = false"
        :selectedItem="selectedItem"
      ></reschedule>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { db } from "@/firebase";
import { mapState } from "vuex";
import reschedule from "./reschedule";

export default {
  props: ["selectedItem", "spaces", "schedule", "bookings"],

  components: {
    reschedule,
  },

  data() {
    return {
      loading: false,
      rescheduleDialog: false,
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    async bookingUsed() {
      this.loading = true;

      await db
        .collection("reservations")
        .doc(this.selectedItem[".key"])
        .update({
          status: "used",
          bookingUsedAt: new Date(),
          bookingStatusChangedBy: this.user[".key"],
        });

      this.loading = false;
      this.$emit("used");
    },

    async validateReservation(e) {
      this.loading = true;

      let payload = null;

      if (e) {
        payload = {
          transferenceConfirmationPending: false,
          transferenceValidatedAt: new Date(),
          transferenceValidatedBy: this.user[".key"],
          status: "available",
        };

        this.selectedItem.locaStatus = "reserved";
        this.selectedItem.status = "available";
      } else {
        this.selectedItem.transferenceValid = false;
        this.selectedItem.locaStatus = "available";

        payload = {
          transferenceConfirmationPending: false,
          transferenceValid: false,
          transferenceValidatedAt: new Date(),
          transferenceValidatedBy: this.user[".key"],
          status: "rejected",
        };
      }

      db.collection("reservations")
        .doc(this.selectedItem[".key"])
        .update(payload)
        .then((r) => {
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });

      // this.$emit("used");
    },

    openImage(url) {
      window.open(url, "_blank").focus();
    },

    // reschedule() {
    //   this.rescheduleDialog = true
    // },
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) {
        return "no disponible";
      }

      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY hh:mm A");
    },
  },
};
</script>

<style scoped>
</style>