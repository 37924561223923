<template>
  <v-card>
    <v-card-title class="justify-center">
      Reservaciones de {{ user.name }}

      <v-btn @click="$emit('cancel')" absolute right icon>
        <v-icon> fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-3">
      <v-text-field
        label="Buscar"
        rounded
        outlined
        v-model="search"
        clearable
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="items"
        sort-by="date"
        :loading="loading"
        :items-per-page="10"
        style="border-radius: 20px"
      >
        <template v-slot:[`item.total`]="{ item }">
          {{ item.total | currency }}
        </template>

        <template v-slot:[`item.code`]="{ item }">
          <v-chip color="primary">{{ item.code }}</v-chip>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip small style="width: 100%;  justify-content: center;" :color="item.status == 'used' ? 'gray' : 'green'">{{
            item.status | filterStatus
          }}</v-chip>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAt | date }}
        </template>

        <template v-slot:[`item.startHour`]="{ item }">
          {{ item.startHour }} - {{ item.endHour }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>
    
    <script>
import { db } from "@/firebase";
import moment from "moment";

export default {
  props: ["user"],

  data() {
    return {
      items: [],
      search: "",
      loading: true,
      headers: [
        {
          text: "Código",
          value: "code",
        },
        {
          text: "Agencia",
          value: "agencyName",
          width: "200px",
        },

        {
          text: "Estado",
          value: "status",
        },

        {
          text: "Fecha de reserva",
          value: "date",
          width: "150px",
        },

        {
          text: "Espacio",
          value: "spaceName",
        },

        {
          text: "Hora",
          value: "startHour",
          width: "150px",
        },

        // {
        //   text: "Hora final",
        //   value: "endHour",
        // },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Creación",
          value: "createdAt",
          width: "250px",
        },
      ],
    };
  },

  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    filterStatus(e) {
      let status = {
        used: "Usado",
        available: "Disponible",
      };

      return status[e] ?? ""
    },

    date(e) {
      return moment(e.toDate())
        .tz("America/Tegucigalpa")
        .format("YYYY-MM-DD hh:mm A");
    },
  },

  async mounted() {
    await this.$binding(
      "items",
      db.collection("reservations").where("userId", "==", this.user[".key"])
    );

    this.loading = false;
  },
};
</script>
  