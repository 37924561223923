<template>
  <v-container fluid>
    <table-custom :headers="headers" :items="events"></table-custom>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import tableCustom from "@/components/table";

export default {
  name: "events",

  components: {
    tableCustom,
  },

  data() {
    return {
      headers: [
        { text: "Campo 1", value: "field1" },
        { text: "Campo 2", value: "field2" },
      ],
      events: [],
    };
  },

  computed: {
    ...mapState(["search"]),
  },

  methods: {
    ...mapActions(["setPage"]),
  },

  mounted() {
    this.setPage({
      title: "Eventos",
      showSearch: true,
      showNavigation: true,
      showAppBar: true,
    });
  },
};
</script>

<style scoped>
</style>