<template>
  <v-card color="secondary">
    <v-card-title class="mx-3">
      <v-card color="primary" width="100%" elevation="5">
        <v-card-text>
          <v-row>
            <v-col
              style="display: flex; justify-content: flex-start"
              cols="12"
              sm="3"
              md="3"
            >
              <h2>Modificar reserva</h2>
            </v-col>
            <v-col
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
              cols="12"
              sm="9"
              md="9"
            >
              <v-icon> fas fa-user</v-icon>
              <span class="ml-3 font-weight-bold">{{ item.userName }}</span>

              <v-icon class="ml-10"> fas fa-chair</v-icon>
              <span class="ml-3 font-weight-bold">{{ item.spaceName }}</span>

              <v-icon class="ml-10"> fas fa-calendar</v-icon>
              <span class="ml-3 font-weight-bold">{{ item.date }}</span>

              <v-icon class="ml-10"> fas fa-clock</v-icon>
              <span class="ml-3 font-weight-bold"
                >{{ item.startHour }} - {{ item.endHour }}</span
              >

              <v-btn color="gray" @click="$emit('cancel')" text icon class="ml-7"
                ><v-icon>fas fa-times</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-card elevation="5">
              <v-card-title>
                <v-chip color="primary" class="mr-5">1</v-chip> Seleccionar
                fecha</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <v-date-picker
                  no-title
                  @change="getBookings"
                  v-model="date"
                  color="primary"
                  :min="min"
                ></v-date-picker>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="8" md="8">
            <v-card height="100%" elevation="5">
              <v-card-title>
                <v-chip color="primary" class="mr-5">2</v-chip> Seleccionar
                horario</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text class="mt-5">
                <v-btn
                  @click="selectedSchedule = e['.key']"
                  :color="selectedSchedule == e['.key'] ? 'primary' : 'gray'"
                  :outlined="selectedSchedule == e['.key'] ? false : true"
                  class="mx-5 my-2 pa-5"
                  v-for="(e, id) of schedule"
                  :key="id"
                >
                  {{ e.startHour }} - {{ e.endHour }}</v-btn
                >
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-card elevation="5">
              <v-card-title>
                <v-chip color="primary" class="mr-5">3</v-chip> Seleccionar
                espacio</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text class="mt-5">
                <v-btn
                  @click="selectedSpace = e['.key']"
                  :color="selectedSpace == e['.key'] ? 'primary' : 'gray'"
                  :outlined="selectedSpace == e['.key'] ? false : true"
                  class="mx-5 my-2 pa-5"
                  v-for="(e, id) of availableSpaces"
                  :key="id"
                  :disabled="!e.available"
                >
                  {{ e.name }}</v-btn
                >
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-select
            item-text="name"
            item-value=".key"
            outlined
            v-model="item.spaceId"
            label="Seleccionar espacio"
            :items="spaces"
          ></v-select>

          <v-select
            outlined
            item-text="startHour"
            item-value=".key"
            v-model="item.scheduleId"
            :items="schedule"
          ></v-select> -->
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-row>
        <v-col cols="12" sm="10" md="10">
          <!-- <v-btn
            :disabled="loading"
            @click="$emit('cancel')"
            block
            height="40"
            outlined
            color="red"
            >Cancelar</v-btn
          > -->
        </v-col>

        <v-col class="mb-5 pr-10" cols="12" sm="2" md="2">
          <v-btn
            height="50"
            :disabled="loading || !selectedSpace"
            @click="save"
            block
            color="primary"
            >Actualizar</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      v-if="confirmDialog"
      v-model="confirmDialog"
      transition="dialog-top-transition"
      max-width="500"
    >
      <v-card>
        <v-card-title class="justify-center">Confirmar acción</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="12" sm="5" md="5">
              <!-- Reserva actual
              <v-divider></v-divider> -->

              <p class="mt-3">Espacio <br />{{ item.spaceName }}</p>
              <p class="mt-3">Fecha <br />{{ item.date }}</p>
              <p class="mt-3">
                Hora <br />{{ item.startHour }} - {{ item.endHour }}
              </p>
            </v-col>
            <v-col
              style="display: flex; align-items: center"
              cols="12"
              sm="2"
              md="2"
            >
              <v-icon color="primary" x-large>fas fa-arrow-right</v-icon>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <!-- Reserva actualizada <v-divider></v-divider> -->

              <p class="mt-3">Espacio <br />{{ newBooking.spaceName }}</p>
              <p class="mt-3">Fecha <br />{{ newBooking.date }}</p>
              <p class="mt-3">
                Hora <br />{{ newBooking.startHour }} - {{ newBooking.endHour }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-btn
                :disabled="loading"
                block
                outlined
                @click="confirmDialog = false"
                color="red"
                >Cancelar</v-btn
              >
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-btn
                :loading="loading"
                @click="update"
                block
                outlined
                color="primary"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/firebase";
import moment from "moment";

export default {
  props: ["selectedItem", "spaces", "schedule"],

  data() {
    return {
      selectedSchedule: null,
      selectedSpace: null,
      confirmDialog: false,
      bookings: [],
      newBooking: {},
      loading: false,
      item: {},
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      min: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },

  computed: {
    ...mapState(["search", "user", "selectedAgency"]),

    availableSpaces() {
      if (this.selectedSchedule && this.date) {
        let date = moment(this.date, "YYYY-MM-DD").format("DD-MM-YYYY");

        let spaces = [];


        this.spaces.forEach((element) => {
          let search = this.bookings.find(
            (p) =>
              p.date == date &&
              p.scheduleId == this.selectedSchedule &&
              p.spaceId == element[".key"]
          );

          if (!search) {
            element.available = true;
          } else {
            element.available = false;
          }

          spaces.push(element);
        });

        return spaces;
      }
    },
  },

  watch: {
    selectedSchedule() {
      this.selectedSpace = null;
    },
  },

  methods: {
    async update() {
      this.loading = true;

      this.newBooking.bookingUpdatedAt = new Date();
      this.newBooking.bookingUpdatedBy = this.user[".key"];
      this.newBooking.oldBookingData = {
        date: this.item.date,
        scheduleId: this.item.scheduleId,
        startHour: this.item.startHour,
        spaceId: this.item.spaceId,
        endHour: this.item.endHour,
        spaceName: this.item.spaceName,
        tripleKey: this.item.tripleKey,
      };

      await db
        .collection("reservations")
        .doc(this.item[".key"])
        .update(this.newBooking);

      this.loading = false;
      this.confirmDialog = false;
      this.$emit("success");
    },
    save() {
      let date = moment(this.date, "YYYY-MM-DD").format("DD-MM-YYYY");

      let schedule = this.schedule.find(
        (e) => e[".key"] == this.selectedSchedule
      );

      let space = this.spaces.find((e) => e[".key"] == this.selectedSpace);

      this.newBooking = {
        date,
        scheduleId: this.selectedSchedule,
        startHour: schedule.startHour,
        spaceId: this.selectedSpace,
        endHour: schedule.endHour,
        spaceName: space.name,
        tripleKey: `${moment(this.date, "YYYY-MM-DD").format("DDMMYYYY")}${
          this.selectedSpace
        }${this.selectedSchedule}`,
      };

      this.confirmDialog = true;
    },
    async getBookings() {
      let date = moment(this.date, "YYYY-MM-DD").format("DD-MM-YYYY");
      this.selectedSpace = null;
      this.selectedSchedule = null;

      await this.$binding(
        "bookings",
        db
          .collection("reservations")
          .where("businessId", "==", this.user.businesses[0])
          .where("agencyId", "==", this.selectedAgency)
          .where("date", "==", date)
      );
    },
  },

  mounted() {
    this.item = Object.assign({}, this.selectedItem);

    this.spaces = this.spaces.filter((e) => e.active);
    this.schedule = this.schedule.filter((e) => e.active);



    this.getBookings();
  },
};
</script>

<style lang="scss" scoped>
</style>