<template>
  <v-card class="pa-0">
    <v-card-title class="justify-center">
      Organizar Espacios

      <v-btn @click="$emit('cancel')" text absolute right>
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      style="display: flex; justify-content: center; align-items: center"
    >
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div id="main-div" class="main-s" :style="workArea">
        <vue-draggable-resizable
          :w="item.coordinates.width ? item.coordinates.width : 100"
          class="item-added"
          :h="item.coordinates.height ? item.coordinates.height : 100"
          :x="item.coordinates.x"
          :y="item.coordinates.y"
          v-for="(item, id) of localSpaces"
          :key="id"
          :parent="true"
          @dragging="onDrag"
          class-name-resizing="resizing"
          @resizing="onResize"
          @activated="selectedItem = item['.key']"
        >
          <h3>{{ item.name }}</h3>
        </vue-draggable-resizable>
      </div>
    </v-card-text>

    <v-card-actions>
      <!-- <v-container> -->
      <v-row>
        <v-col cols="12" sm="4" md="4"></v-col>
        <v-col
          style="
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
          "
          class="pa-10"
          cols="12"
          sm="8"
          md="8"
        >
          <v-btn @click="save" color="primary">Actualizar</v-btn>
        </v-col>
      </v-row>
      <!-- </v-container> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import { db } from "@/firebase";
import { mapActions } from "vuex";

export default {
  name: "",
  props: ["spaces"],
  components: {
    VueDraggableResizable,
  },

  data() {
    return {
      selectedItem: null,
      newProps: {},
      localSpaces: [],
      overlay: true,
      selectedGrid: 10,
    };
  },

  computed: {
    workArea() {
      let doc = document.getElementById("main-div");
      let height = doc ? doc.offsetHeight : 100;
      let width = doc ? doc.offsetWidth : 100;

      return {
        "background-size": `${width / this.selectedGrid}px ${
          height / this.selectedGrid
        }px`,
      };
    },
  },

  methods: {
    ...mapActions(["setSnackbar"]),

    onDrag(x, y) {
      let height = document.getElementById("main-div").offsetHeight;
      let width = document.getElementById("main-div").offsetWidth;

      this.newProps[this.selectedItem].x = Number.parseFloat((x / width) * 100);
      this.newProps[this.selectedItem].y = Number.parseFloat(
        (y / height) * 100
      );
    },

    onResize(x, y, newWidth, newHeight) {
      let height = document.getElementById("main-div").offsetHeight;
      let width = document.getElementById("main-div").offsetWidth;

      this.newProps[this.selectedItem].width = Number.parseFloat(
        (newWidth / width) * 100
      );
      this.newProps[this.selectedItem].height = Number.parseFloat(
        (newHeight / height) * 100
      );
      this.newProps[this.selectedItem].x = Number.parseFloat((x / width) * 100);

      this.newProps[this.selectedItem].y = Number.parseFloat(
        (y / height) * 100
      );
    },

    async save() {
      this.overlay = true;
      let keys = Object.keys(this.newProps);

      for (let i = 0; i < keys.length; i++) {
        const id = keys[i];

        await db.collection("spaces").doc(id).update({
          coordinates: this.newProps[id],
        });
        this.overlay = false;

        this.setSnackbar({
          message: "Registros actualizados exitosamente.",
          color: "green",
        });
      }
    },
  },

  mounted() {
    for (let i = 0; i < this.spaces.length; i++) {
      this.newProps[this.spaces[i][".key"]] = this.spaces[i].coordinates;
    }

    setTimeout(() => {
      let height = document.getElementById("main-div").offsetHeight;
      let width = document.getElementById("main-div").offsetWidth;

      this.localSpaces = JSON.parse(JSON.stringify(this.spaces));

      this.localSpaces = this.localSpaces.map((space) => {
        let newCoor = {
          x: width * (space.coordinates.x / 100),
          y: height * (space.coordinates.y / 100),
          width: width * (space.coordinates.width / 100),
          height: height * (space.coordinates.height / 100),
        };

        space.coordinates = newCoor;

        return space;
      });

      this.overlay = false;
      this.selectedGrid = 6;
    }, 1000);
  },
};
</script>


<style scoped>
.item-added {
  background-color: #ff8a00;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  position: absolute;
  /* z-index: 9999; */
  justify-items: center;
  min-height: 20px;
  min-width: 20px;
  cursor: pointer;
}

.main-s {
  height: 80%;
  width: 80%;
  border: 1px solid #ff8a00;
  position: relative;

  border: 1px solid rgba(255, 255, 255, 0.4);

  background-image: linear-gradient(-90deg, #767676 1px, transparent 0),
    linear-gradient(#767676 1px, transparent 0);

  background-position: 0 0;
  background-repeat: repeat, repeat;
  background-attachment: scroll, scroll;
  margin-top: 20px;
  overflow: hidden;
}

.resizing {
  opacity: 0.4;
  border: 1px solid red !important;
}
</style>