import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";
import bookings from "../views/bookings.vue";
import events from "../views/events.vue";
import schedule from "../views/schedule";
import spaces from "../views/spaces";
import prices from "../views/prices";
import leaderboard from "../views/leaderboard";
import users from "../views/users";
import banners from "../views/banners";
import agencies from "../views/agencies";
import list from "../views/list";



Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
  },

  {
    path: "/list",
    name: "list",
    component: list,
  },

  {
    path: "/agencies",
    name: "agencies",
    component: agencies,
  },

  {
    path: "/events",
    name: "events",
    component: events,
  },

  {
    path: "/bookings",
    name: "bookings",
    component: bookings,
  },

  {
    path: "/schedule",
    name: "schedule",
    component: schedule,
  },

  {
    path: "/spaces",
    name: "spaces",
    component: spaces,
  },

  {
    path: "/prices",
    name: "prices",
    component: prices,
  },

  {
    path: "/leaderboard",
    name: "leaderboard",
    component: leaderboard,
  },


  {
    path: "/users",
    name: "users",
    component: users,
  },

  {
    path: "/banners",
    name: "banners",
    component: banners,
  },

  {
    path: "/",
    redirect: "/bookings",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
