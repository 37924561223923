import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
// import "firebase/compat/app-check";

let env = process.env.NODE_ENV;

var prodConfig = {
  apiKey: "AIzaSyDmBH0vQxvwuLk607Us3v53_G9H3txwopo",
  authDomain: "inercia-733ab.firebaseapp.com",
  projectId: "inercia-733ab",
  storageBucket: "inercia-733ab.appspot.com",
  messagingSenderId: "467524480192",
  appId: "1:467524480192:web:b79dceb5982873581b1125",
};

const devConfig = {
  apiKey: "AIzaSyDmBH0vQxvwuLk607Us3v53_G9H3txwopo",
  authDomain: "inercia-733ab.firebaseapp.com",
  projectId: "inercia-733ab",
  storageBucket: "inercia-733ab.appspot.com",
  messagingSenderId: "467524480192",
  appId: "1:467524480192:web:b79dceb5982873581b1125",
};

firebase.initializeApp(env == "production" ? prodConfig : devConfig);

firebase.auth().languageCode = "es";
firebase.auth().tenantId = "admin-panel-r1toj";

if (window.origin.includes("localhost")) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.VUE_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
  // firebase.functions().useEmulator("localhost", "5001");
}

export const db = firebase.firestore();
export const fb = firebase;
