import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    defaults: {
      global: {
        ripple: false,
      },
      VSheet: {
        elevation: 0,
      },
      VCard: {
        style: 'border-radius: 10px !important;background-color: #171717; border: solid 1px #262626;',
        variant: 'outlined',
      },
      VBtn: {
        style: 'border-radius:10px !important; ',
      },
      VImg: {
        style: 'border-radius:10px !important;',
      },
      Vchip: {
        style: 'border-radius:3px !important;',
  
      },
      VTextField: { variant: 'outlined', color: 'primary', style: 'border-radius:10px !important;', }
  
    },
    themes: {
      light: {
        primary: "#FF8A00",
        secondary: "#0C0C0C",
        tertiary: "#121212",
      },
      dark: {
        primary: "#FF8A00",
        secondary: "#0C0C0C",
        tertiary: "#121212",
      },
    },
  },
});
