<template>
  <v-card>
    <v-card-title class="justify-center">Nueva rerserva</v-card-title>
    <!-- <v-divider></v-divider> -->
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="3" md="3"
          ><v-icon class="mt-5" large color="primary">fas fa-chair</v-icon>
          <p class="mt-3">{{ space.name }}</p></v-col
        >

        <v-col cols="12" sm="3" md="3">
          <v-icon class="mt-5" large color="primary">fas fa-calendar</v-icon>
          <p class="mt-3">{{ date }}</p>
        </v-col>

        <v-col cols="12" sm="3" md="3"
          ><v-icon class="mt-5" large color="primary">fas fa-clock</v-icon>
          <p class="mt-3">
            {{ scheduleInfo.startHour }} - {{ scheduleInfo.endHour }}
          </p></v-col
        >

        <v-col cols="12" sm="3" md="3"
          ><v-icon class="mt-5" large color="primary"
            >fas fa-dollar-sign</v-icon
          >
          <p class="mt-3">
            {{ (price.price + extraFees - discounts) | currency }}
          </p></v-col
        >

        <v-col cols="12" sm="12" md="12">
          <v-autocomplete
            outlined
            item-text="name"
            item-value=".key"
            :items="users"
            v-model="selectedUser"
            label="Seleccionar usuario"
          >
            <template v-slot:selection="data">
              {{ data.item.name }}
              <small class="ml-3" style="color: #ff8a00" v-if="data.item.phone"
                >({{ data.item.phone }})</small
              >
            </template>

            <template v-slot:no-data>
              <small
                >No se encontraron resultados
                <strong
                  @click="addUserDialog = true"
                  style="color: #ff8a00; cursor: pointer"
                  class="ml-5"
                  >Crear usuario</strong
                ></small
              >
            </template>

            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <small style="color: #ff8a00"
                      ><b v-if="data.item.phone">{{ data.item.phone }}</b>
                      <br v-if="data.item.email && data.item.phone" />
                      <b v-if="data.item.email">{{ data.item.email }}</b>
                    </small>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <!-- <small
                >No se encontraron resultados
                <strong
                  @click="addUserDialog = true"
                  style="color: #ff8a00; cursor: pointer"
                  class="ml-5"
                  >Crear usuario</strong
                ></small
              > -->
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <div class="billing">
            <p style="text-align: end">Valor del espacio:</p>
            <p style="text-align: end">{{ price.price | currency }}</p>
          </div>

          <div class="billing" v-for="(item, id) of getAgencyFees" :key="id">
            <p style="text-align: end">{{ item.name }}:</p>
            <p style="text-align: end">{{ calculateFee(item) | currency }}</p>
          </div>

          <div class="billing">
            <p style="text-align: end">Descuento:</p>
            <p style="text-align: end">{{ discounts | currency }}</p>
          </div>

          <div class="billing">
            <p style="text-align: end">Total:</p>
            <p style="text-align: end">
              {{ (price.price + extraFees - discounts) | currency }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-btn
            :disabled="loading"
            @click="$emit('cancel')"
            block
            outlined
            color="red"
            >Cancelar</v-btn
          >
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-btn
            :disabled="!selectedUser"
            :loading="loading"
            block
            @click="reserve"
            color="primary"
            >Reservar</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      v-if="addUserDialog"
      v-model="addUserDialog"
      transition="dialog-top-transition"
      max-width="500"
    >
      <add
        :fields="fields"
        :loading="loading"
        @create="createItem"
        @cancel="addUserDialog = false"
      ></add>
    </v-dialog>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import add from "@/components/add";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { nanoid } from "nanoid";

export default {
  name: "make-booking",
  props: ["spaces", "schedule", "selectedItem", "date"],
  components: {
    add,
  },
  data() {
    return {
      users: [],
      selectedUser: null,
      addUserDialog: false,
      price: {},
      space: {},
      agency: {},
      extraFees: 0,
      discounts: 0,
      scheduleInfo: {},
      loading: false,
      fields: [
        {
          value: "name",
          type: "text",
          rules: ["required"],
          label: "Ingrese el nombre completo",
        },
        {
          value: "email",
          type: "text",
          rules: ["required", "email"],
          label: "Ingrese el correo electrónico",
        },

        {
          value: "phone",
          type: "phone",
          rules: ["required"],
          label: "Ingrese el número de teléfono",
        },
      ],
    };
  },

  computed: {
    ...mapState(["selectedAgency", "user"]),
    getAgencyFees() {
      return this.agency && this.agency.fees
        ? this.agency.fees.filter((e) => e.active)
        : [];
    },
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },

  methods: {
    ...mapActions(["setSnackbar"]),

    createItem(e) {
      let data = {
        email: e.email,
        password: nanoid(),
        name: e.name,
        phone: `+${e.callingCode}${e.phone}`,
      };

      this.loading = true;

      var createUser = fb.functions().httpsCallable("create-create");
      createUser(data)
        .then((response) => {
          this.setSnackbar({
            message: response.data.message,
            color: "green",
          });

          this.loading = false;
          this.addUserDialog = false;
        })
        .catch((err) => {
          this.loading = false;
          this.setSnackbar({
            message: err.message,
            color: "red",
          });
        });
    },

    calculateFee(e) {
      let amount = 0;

      if (e.type == "fixed") {
        amount = e.amount;
      } else {
        amount = (e.amount * this.price.price) / 100;
      }

      return amount;
    },

    async reserve() {
      let date = moment(this.date, "YYYY-MM-DD").format("DD-MM-YYYY");
      let user = this.users.find((e) => e[".key"] == this.selectedUser);

      let business = await db
        .collection("businesses")
        .doc(this.user.businesses[0])
        .get();
      business = business.data();

      this.loading = true;

      let payload = {
        agencyId: this.selectedAgency,
        agencyName: this.agency.name,
        businessId: this.user.businesses[0],
        businessLogo: business.logo,
        businessName: business.name,
        code: "",
        createdAt: new Date(),
        createdBy: this.user[".key"],
        date,
        endHour: this.scheduleInfo.endHour,
        eventBanner: "",
        eventDescription: "",
        eventId: "",
        eventName: "",
        fees: [],
        extraFees: this.extraFees,
        discount: this.discounts,
        priceId: this.space.priceId,
        price: this.price.price,
        purchaseId: "",
        purchaseType: "reservation",
        scheduleId: this.selectedItem.scheduleId,
        spaceId: this.selectedItem.spaceId,
        spaceName: this.space.name,
        startHour: this.scheduleInfo.startHour,
        status: "available",
        subtotal: this.price.price,
        total: this.price.price + this.extraFees - this.discounts,
        tripleKey: `${moment(this.date, "YYYY-MM-DD").format("DDMMYYYY")}${
          this.selectedItem.scheduleId
        }${this.selectedItem.spscaceId}`,
        userEmail: user.email ?? "",
        userId: this.selectedUser,
        userName: user.name ?? "",
        userPhone: user.phone ?? "",
        paymentStatus: "pending",
        transferenceValid: true,
        transferenceConfirmationPending: false,
        paymentMethodName: "cash",
      };

      await db.collection("reservations").add(payload);

      this.$emit("success");
    },
  },

  async mounted() {
    this.$binding(
      "users",
      db
        .collection("users")
        .where("type", "==", "client")
        .orderBy("name", "asc")
    );

    this.scheduleInfo = this.schedule.find(
      (e) => e[".key"] == this.selectedItem.scheduleId
    );

    this.space = this.spaces.find(
      (e) => e[".key"] == this.selectedItem.spaceId
    );

    await this.$binding(
      "price",
      db.collection("prices").doc(this.space.priceId)
    );

    await this.$binding(
      "agency",
      db.collection("agencies").doc(this.selectedAgency)
    );

    let fees = this.getAgencyFees;

    fees.forEach((e) => {
      if (e.type == "fixed") {
        this.extraFees += e.amount;
      } else {
        this.extraFees += (e.amount * this.price.price) / 100;
      }
    });

    if (this.price.discountActive) {
      this.discounts =
        this.price.discountType == "fixed"
          ? this.price.discountAmount
          : (this.price.price * this.price.discountAmount) / 100;
    }
  },
};
</script>

<style>
.billing {
  display: grid;
  grid-template-columns: 70% 30%;
}
</style>