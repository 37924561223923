<template>
  <v-app>
    <v-snackbar centered :timeout="10000" top v-model="getSnackbar" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeSnackbar">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay  absolute dark z-index="999" :value="overlay">
      <div class="overlay-d">
        <lottie :options="defaultOptions" v-on:animCreated="handleAnimation" :height="200" :width="400" />

        <h3 style="position: absolute; bottom: -20px">Cargando...</h3>
      </div>
    </v-overlay>

    <v-navigation-drawer v-if="(page.showNavigation || drawer) && !overlay" width="280" permanent color="secondary"
      fixed app>
      <template v-slot:prepend>
        <v-img src="@/assets/logo.svg"></v-img>

        <v-list-item v-if="user">
          <v-list-item-content>
            <v-list-item-title><b>{{ user.name }}</b></v-list-item-title>
            <v-list-item-subtitle style="color: #ff8a00">{{
      role.name
    }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </template>

      <v-list dense class="mt-5" nav>
        <v-list-item class="mt-2" :to="item.path" v-for="(item, id) in getMenu" :key="id">
          <v-list-item-icon class="mr-0 pr-0">
            <v-icon color="
            primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider class="mb-5"></v-divider>
        <v-btn @click="logout" block outlined height="40" color="primary">
          <v-icon left>fas fa-sign-out-alt</v-icon> <b>Cerrar sesión</b></v-btn>
      </template>
    </v-navigation-drawer>

    <v-app-bar v-if="page.showAppBar" fixed class="pt-5 pb-5" color="tertiary" app flat min-height="100">
      <!-- <v-app-bar-nav-icon v-if="page.showMenuIcon" @click="drawer = !drawer"></v-app-bar-nav-icon> -->
      <h2 style="color: #ff8a00; text-align: start" class="title-theme">
        {{ page.title }}
      </h2>
      <v-spacer></v-spacer>

      <v-text-field hide-details label="Buscar" dense dark v-model="search" v-if="page.showSearch" append-icon="fas fa-search"
        class="mr-5" rounded style="width: 350px; max-width: 350px;" filled></v-text-field>

      <v-select hide-details v-model="agencyValue"  rounded  filled :items="agencies" append-outer-icon="fas fa-store-alt" class="mr-4"
        item-value=".key" item-text="name" style="width: 250px; max-width: 350px;" placeholder="Agencia"></v-select>
    </v-app-bar>

    <v-main v-if="!overlay" class="mt-7">
      <v-container fluid class="pa-5">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer class="justify-center" height="30px" color="secondary" fixed app>
      <small style="font-size: 12px"> <a href="http://nimbux.hn/" class="a-nimbux" target="_blank"><code style="background-color: #0C0C0C">{{`</>`}} Desarollado por Nimbux Solutions S.A</code></a> </small>
    </v-footer>
  </v-app>
</template>


<script>
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import Lottie from "./components/Lottie";
import * as animationData from "./assets/loading.json";
import sort from "@/mixins/sort";

export default {
  name: "App",

  components: {
    lottie: Lottie,
  },
  mixins: [sort],

  data() {
    return {
      search: null,
      overlay: true,
      drawer: false,
      agencies: [],
      anim: null,
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      items: [
         {
          title: "HISTÓRICO",
          icon: "fas fa-list",
          path: "list",
        },
        {
          title: "RESERVAS",
          icon: "fas fa-calendar-check",
          path: "bookings",
        },
        {
          title: "HORARIOS",
          icon: "fas fa-clock",
          path: "schedule",
        },
        {
          title: "ESPACIOS",
          icon: "fas fa-chair",
          path: "spaces",
        },
        {
          title: "PRECIOS",
          icon: "fas fa-dollar-sign",
          path: "prices",
        },

        {
          title: "USUARIOS",
          icon: "fas fa-users",
          path: "users",
        },

        {
          title: "BANNERS",
          icon: "fas fa-images",
          path: "banners",
        },

        {
          title: "AGENCIAS",
          icon: "fas fa-store",
          path: "agencies",
        },

        // {
        //   title: "CLASIFICACIONES",
        //   icon: "fas fa-sort-numeric-up",
        //   path: "leaderboard",
        // },
      ],
    };
  },

  filters: {
    role(e) {
      let roles = {
        admin: "Administrador",
      };

      return roles[e] ?? "Desconocido";
    },
  },

  watch: {
    search(e) {
      this.setSearch(e);
    },
  },

  computed: {
    ...mapState([
      "page",
      "selectedAgency",
      "user",
      "snackbarText",
      "snackbar",
      "role",
      "snackbarColor",
    ]),

    getMenu() {
      let menu = this.items.filter(
        (e) => this.role.screens[e.path] && this.role.screens[e.path].read
      );

      return menu;
    },

    getSnackbar: {
      get() {
        return this.snackbar;
      },

      set(e) {
        this.closeSnackbar(e);
      },
    },

    agencyValue: {
      get() {
        return this.selectedAgency;
      },
      set(e) {
        this.setAgency(e);
      },
    },

    userRole: {
      get() {
        return this.role;
      },
      set(e) {
        this.setRole(e);
      },
    },

    userInfo: {
      get() {
        return this.user;
      },
      set(e) {
        this.setUser(e);
      },
    },
  },

  methods: {
    ...mapActions([
      "setSearch",
      "setAgency",
      "setUser",
      "closeSnackbar",
      "setSnackbar",
      "setRole",
      "setPage",
    ]),
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    logout() {
      this.overlay = false;

      this.setPage({
        title: "",
        showSearch: false,
        showNavigation: false,
        showAppBar: false,
      });

      this.drawer = false;

      fb.auth()
        .signOut()
        .then(() => {})
        .catch(() => {});
    },
  },

  async mounted() {
    fb.auth().onAuthStateChanged(async (auth) => {
      if (!auth) {
        this.$router.push({ path: "/login" }).catch((e) => {});
        this.overlay = false;

        return;
      }

      this.overlay = true;

      await this.$binding("userInfo", db.collection("users").doc(auth.uid));

      await this.$binding(
        "agencies",
        db
          .collection("agencies")
          .where(fb.firestore.FieldPath.documentId(), "in", this.user.agencies)
          .where("businessId", "==", this.user.businesses[0])
          .where("active", "==", true)
      );

      await this.$binding(
        "userRole",
        db.collection("roles").doc(this.user.roleId)
      );

      this.agencies = this.sortedArray(this.agencies);

      let path = this.$route.path == "/login" ? "/bookings" : this.$route.path;
      this.$router.push({ path: path }).catch((e) => {});

      this.overlay = false;
    });
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";
@import "~@/assets/scss/_responsive.scss";

@font-face {
  font-family: "conthrax-sb";
  src: url("~@/assets/fonts/conthrax-sb.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "conthrax-regular";
  src: url("~@/assets/fonts/conthrax-regular.otf");
  font-weight: normal;
  font-style: normal;
}

.title-theme {
  font-family: "conthrax-sb";
}

#app {
  font-family: "conthrax-regular", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.overlay-d {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.a-nimbux {
  text-decoration: none;
  color: #d1d1d1 !important;
}
</style>
