<template>
  <v-container fluid>
    <div style="display: flex; justify-content: flex-start; padding-left: 18px">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Seleccionar dia para ver las reservas"
            readonly
            style="max-width: 300px"
            v-bind="attrs"
            v-on="on"
            :loading="loading"
            hide-details
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" color="primary" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text @click="menu = false"> Cancelar </v-btn>
          <v-btn
            color="primary"
            @click="
              $refs.menu.save(date);
              getData();
            "
          >
            Buscar
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-spacer></v-spacer>

      <v-chip small id="busy" color="red"></v-chip>
      <label for="busy" class="white--text ml-3 mr-8">Revisión pendiente</label>

      <v-chip small id="busy" color="primary"></v-chip>
      <label for="busy" class="white--text ml-3">Reservado</label>

      <v-chip class="ml-10" small id="busy" color="gray" outlined></v-chip>
      <label for="busy" class="white--text ml-3">Disponible</label>

      <v-chip class="ml-10" small id="busy" color="gray"></v-chip>
      <label for="busy" class="white--text ml-3">Usado</label>
    </div>
    <div class="booking-main">
      <div
        :class="{ 'booking-row': true, headers: id == 0 }"
        v-for="(row, id) of items"
        :key="id"
      >
        <p
          v-for="(text, key) of row"
          :key="key"
          :class="{
            'booking-item': true,
            'no-left-border': key == 0,
            'no-top-border': id == 0,
          }"
        >
          <span v-if="isObject(text)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  @click="viewBooking(text)"
                  style="cursor: pointer"
                  small
                  v-on="on"
                  v-bind="attrs"
                  v-if="text.locaStatus == 'reserved'"
                  color="primary"
                ></v-chip>
              </template>
              <span>Espacio reservado</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  @click="viewBooking(text)"
                  style="cursor: pointer"
                  small
                  v-on="on"
                  v-bind="attrs"
                  v-if="
                    text.locaStatus == 'pendingValidation' &&
                    text.transferenceValid
                  "
                  color="red"
                ></v-chip>
              </template>
              <span>Pendiente de validación</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  small
                  v-on="on"
                  outlined
                  v-bind="attrs"
                  @click="makeBooking(text)"
                  v-if="text.locaStatus == 'available'"
                  color="gray"
                ></v-chip>
              </template>
              <span>Espacio disponible</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  small
                  v-on="on"
                  v-bind="attrs"
                  @click="viewBooking(text)"
                  v-if="text.locaStatus == 'used'"
                  color="gray"
                ></v-chip>
              </template>
              <span>Reserva utilizada</span>
            </v-tooltip>
          </span>

          <span v-else>{{ text }}</span>
        </p>
      </div>
    </div>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      v-if="viewDialog"
      v-model="viewDialog"
      transition="dialog-top-transition"
      max-width="550"
    >
      <information
        :spaces="spaces"
        :schedule="schedule"
        :bookings="bookings"
        @used="bookingUsed"
        @success="handleInfoSuccess"
        @cancel="viewDialog = false; getData()"
        :selectedItem="selectedItem"
      ></information>
    </v-dialog>

    <v-dialog
      :overlay-opacity="0.85"
      scrollable
      persistent
      v-if="makeDialog"
      v-model="makeDialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <make
        :spaces="spaces"
        :schedule="schedule"
        @success="handleMakeSuccess"
        @cancel="makeDialog = false"
        :date="date"
        :selectedItem="selectedItem"
      ></make>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { db } from "@/firebase";
import moment from "moment";
import information from "@/components/view-booking";
import make from "@/components/make-booking";

export default {
  name: "Bookings",

  components: {
    information,
    make,
  },

  data() {
    return {
      bookings: [],
      loading: false,
      spaces: [],
      selectedItem: null,
      viewDialog: false,
      schedule: [],
      items: [],
      makeDialog: false,
      spacesIds: ["_"],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
    };
  },

  watch: {
    selectedAgency(e) {
      if (e) {
        this.getData();
      } else {
        this.bookings = [];
        this.spaces = [];
      }
    },
  },

  computed: {
    ...mapState(["search", "user", "selectedAgency"]),
  },

  filters: {
    filterStatus(e) {
      let status = {
        available: "Disponible",
        reserved: "Reservado",
      };

      return status[e];
    },
  },

  methods: {
    ...mapActions(["setPage", "setSnackbar"]),

    handleInfoSuccess() {
      this.setSnackbar({
        message: "Reserva actualizada exitosamente.",
        color: "green",
      });

      this.viewDialog = false;
      this.getData();
    },

    handleMakeSuccess() {
      this.setSnackbar({
        message: "Reserva creada exitosamente.",
        color: "green",
      });

      this.makeDialog = false;
      this.getData();
    },

    makeBooking(e) {
      // console.log(e);
      // console.log(this.date);
      this.selectedItem = e;
      this.makeDialog = true;
    },

    bookingUsed() {
      this.setSnackbar({
        message: "Reserva marcada como utilizada.",
        color: "green",
      });

      this.viewDialog = false;
      this.getData();
    },

    viewBooking(item) {
      this.selectedItem = item;
      this.viewDialog = true;
    },

    isObject(e) {
      return typeof e == "object" ? true : false;
    },

    async getData() {
      this.loading = true;
      this.items = [];
      this.spacesIds = ["-"];

      let date = moment(this.date, "YYYY-MM-DD").format("DD-MM-YYYY");

      await this.$binding(
        "spaces",
        db
          .collection("spaces")
          .where("businessId", "==", this.user.businesses[0])
          .where("agencyId", "==", this.selectedAgency)
          .where("deleted", "==", false)
          .orderBy("name", "asc")
      );

      await this.$binding(
        "bookings",
        db
          .collection("reservations")
          .where("businessId", "==", this.user.businesses[0])
          .where("agencyId", "==", this.selectedAgency)
          .where("date", "==", date)
      );

      await this.$binding(
        "schedule",
        db
          .collection("dailyTicketSchedule")
          .where("businessId", "==", this.user.businesses[0])
          .where("agencyId", "==", this.selectedAgency)
          .where("deleted", "==", false)
          .orderBy("startHour", "asc")
      );

      let line = [""];

      for (let i = 0; i < this.spaces.length; i++) {
        const space = this.spaces[i];
        this.spacesIds.push(space[".key"]);
        line.push(space.name);
      }

      this.items.push(line);

      for (let i = 0; i < this.schedule.length; i++) {
        line = new Array(this.spacesIds.length);
        line.fill("");

        const s = this.schedule[i];
        line[0] = `${s.startHour} - ${s.endHour}`;

        for (let i = 1; i < this.spacesIds.length; i++) {
          const spaceId = this.spacesIds[i];

          let search = this.bookings.find(
            (e) => e.spaceId == spaceId && e.scheduleId == s[".key"]
          );

          let position = this.spacesIds.indexOf(spaceId);

          if (search) {
            switch (search.status) {
              case "rejected":
                search.locaStatus = "available";
                break;

              case "used":
                search.locaStatus = "used";
                break;

              case "pendingValidation":
                search.locaStatus = "pendingValidation";
                break;

              case "available":
                search.locaStatus = "reserved";
                break;

              default:
                search.locaStatus = "reserved";

                break;
            }

            line[position] = search;
          } else {
            line[position] = {
              spaceId,
              scheduleId: s[".key"],
              locaStatus: "available",
            };
          }
        }

        this.items.push(line);
      }

      this.loading = false;
    },
  },

  mounted() {
    this.setPage({
      title: "Reservaciones",
      showSearch: false,
      showNavigation: true,
      showAppBar: true,
      showMenuIcon: true,
      description:
        "En esta pantalla están las reservaciones de todos los clientes",
    });

    this.getData();
  },
};
</script>

<style scoped lang="scss">
.booking-main {
  width: 100%;
  padding: 12px;
  margin-top: 20px;

  .booking-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 70px;

    .booking-item {
      width: 100%;
      font-size: 12px;
      color: white;
      border: 0.1px solid #ff88003a;
      justify-content: center;
      display: flex;
      align-items: center;
      margin: 0;
      cursor: pointer;
    }
  }
}

.headers {
  p {
    font-size: 16px !important;
  }
}

.available {
  background-color: rgba(120, 248, 131, 0.24);
  padding: 8px;
}

.no-top-border {
  border-top: 0 !important;
}

.no-left-border {
  border-left: 0 !important;
}
</style>